import apiPath from './apiPath'
import {ref} from 'vue'
import { useRouter } from 'vue-router';

const saveUserCategory = ()=>{
    const hata = ref(null);
    const path = apiPath;
    const router = useRouter();
    let users = JSON.parse(localStorage.getItem('user'));
    const postSaveUserCategory = async(categoryIdList,customerId)=>{
        hata.value = null;
        try {
            const res = await fetch(path + "category/saveUserCategory", {
                method: 'POST',
                headers: {
                  'Content-type': 'application/json; charset=UTF-8'
                },
                body: JSON.stringify({
                    "customerId": customerId,
                    "TokenKey": localStorage.getItem('token'),
                    "UserId":users.id,
                    "RequestMethodName" :"/api/category/saveUserCategory",
                    "RequestMethodType" : "POST",
                    "categoryId":categoryIdList
                }), 
            })
            const data = await res.json();
            
            if(data.errorMessage == "Error, token expired."){
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                router.push({name:'login'})              
            }
            else{
                if(data.isSuccess){
                    return data
                }
            }
        } catch (error) {
            hata.value = error.message
            console.log(hata.value);
        }
        
    }
    return {postSaveUserCategory}
}

export default saveUserCategory