<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from 'sweetalert2'

import getFindByBrandIdList from '../../../state/api/getFindByBrandIdList';
import saveUserCategory from '../../../state/api/saveUserCategory';

export default {
  page: {
    title: "Kullanıcı",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader},
  data() {
    return {
      title: "Edit User Category",
      items: [
        {
          text: "Users",
          href: "/"
        },
        {
          text: "Edit User Category",
          active: true
        }
      ],
      id:this.$route.params.id,
      categoryTree : [],
      brandList : [],
      selectedCategories: [],
      selectedCategoriList: [],
      selectedCategoriListId: [],
    };
  },
  methods:{
    async getBrandIdList(customerId){
        const {brandIdList} = getFindByBrandIdList()
        const response = await brandIdList(customerId);
        this.categoryTree = response.data.categoryList;
        this.selectedCategoriList= this.categoryTree.filter(x=>x.isActive === "1");
        this.selectedCategoriListId = this.selectedCategoriList.map(item => item.id);        
        this.brandList = response.data.brandList;
        setTimeout(accordionJS, 0);

        function accordionJS(){
        const items = document.querySelectorAll(".accordion button");
          function toggleAccordion() {
            const itemToggle = this.getAttribute('aria-expanded');        
            for (let i = 0; i < items.length; i++) {
              items[i].setAttribute('aria-expanded', 'false');
            }        
            if (itemToggle == 'false') {
              this.setAttribute('aria-expanded', 'true');
            }
          }
          items.forEach(item => item.addEventListener('click', toggleAccordion));
        }
    },
    async updateUserCategory() {
      let data = this.selectedCategoriListId;
      let customerId = this.id;
        const {postSaveUserCategory} = saveUserCategory()
        await postSaveUserCategory(data,customerId).
        then(res=>{
            if(res.isSuccess){
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Your work has been saved",
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        })
    },
    handleCategoryChange(categoryId) {
      const selectedCategory = this.categoryTree.find(x => x.id === categoryId);
      const add = this.getSubCategories(selectedCategory);
      add.push(selectedCategory);

      if (!this.selectedCategoriListId.includes(categoryId)) {
        this.selectedCategoriListId.push(categoryId);
      } else {
        this.selectedCategoriListId = this.selectedCategoriListId.filter(id => id !== categoryId);
      }
      add.forEach(item => {
        const index = this.selectedCategoriListId.indexOf(item.id);
        if (index === -1) {
          this.selectedCategoriListId.push(item.id);
        } else {
          this.selectedCategoriListId.splice(index, 1);
        }
      });
    },
    getSubCategories(category) {
      const subCategories = this.categoryTree.filter(x => x.parentId === category.id);
      let result = [...subCategories];    
      subCategories.forEach(subCategory => {
        result = result.concat(this.getSubCategories(subCategory));
      });
      return result;
    },
  },
  mounted(){
    this.getBrandIdList(this.id);    
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6"><h4 class="card-title mb-4">{{$t('user.Categories')}}</h4></div>
              <div class="col-md-6"><button class="btn btn-primary float-end" @click="updateUserCategory">Submit</button></div>
            </div>            
            <div class="accordion">
              <div class="accordion-item px-3 mb-3" v-for="(item,index) of brandList" :key="index">
                <button id="accordion-button-1" aria-expanded="false"><span class="accordion-title">{{ item.title }}</span><span class="icon" aria-hidden="true"></span></button>
                <div class="accordion-content">
                  <div class="p-3">
                    <ul class="p-0">
                      <li v-for="category in categoryTree.filter(x=>x.brandId == item.id && x.parentId == 0)" :key="category.id" class="card border border-primary">
                        <div class="d-flex justify-content-start px-3 py-1">
                          <div class="form-check font-size-16">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              :value="category.id"
                              :id="`transactionCheck${category.id}`" 
                              v-model="selectedCategoriListId"
                              @change="handleCategoryChange(category.id)"
                            />
                            <label
                              class="form-check-label"
                              :for="`transactionCheck${category.id}`"
                            ></label>
                          </div>
                          <div class="font-size-16 p-0">
                            {{ category.name }} 
                          </div>
                        </div>                        
                        <ul class="ps-5">
                          <li v-for="category2 in categoryTree.filter(x=>x.parentId == category.id)" :key="category2.id">
                            <div class="d-flex justify-content-start p-1">
                              <div class="form-check font-size-16">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  :value="category2.id"
                                  :id="`transactionCheck${category2.id}`" 
                                  v-model="selectedCategoriListId"
                                  @change="handleCategoryChange(category2.id)"
                                />
                                <label
                                  class="form-check-label"
                                  :for="`transactionCheck${category2.id}`"
                                ></label>
                              </div>
                              <div class="font-size-16 p-0">
                                {{ category2.name }} 
                              </div>
                            </div>
                            <ul>
                              <li v-for="category3 in categoryTree.filter(x=>x.parentId == category2.id)" :key="category3.id">
                                <div class="d-flex justify-content-start p-1">
                                  <div class="form-check font-size-16">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      :value="category3.id"
                                      :id="`transactionCheck${category3.id}`"
                                      v-model="selectedCategoriListId"
                                      @change="handleCategoryChange(category3.id)"
                                    />
                                    <label
                                      class="form-check-label"
                                      :for="`transactionCheck${category3.id}`"
                                    ></label>
                                  </div>
                                  <div class="font-size-16 p-0">
                                    {{ category3.name }} 
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>                
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<style scoped>
.accordion .accordion-item {
  border: 1px solid #e5e5e5;
}
.accordion .accordion-item button[aria-expanded=true] {
  border-bottom: 1px solid #e5e5e5;
}
.accordion button {
  position: relative;
  display: block;
  text-align: left;
  width: 100%;
  padding: 1em 0;
  color: #7288a2;
  font-size: 1.15rem;
  font-weight: 400;
  border: none;
  background: none;
  outline: none;
}
.accordion button:hover, .accordion button:focus {
  cursor: pointer;
  color: #556ee6;
}
.accordion button:hover::after, .accordion button:focus::after {
  cursor: pointer;
  color: #556ee6;
  border: 1px solid #556ee6;
}
.accordion button .accordion-title {
  padding: 1em 1.5em 1em 0;
}
.accordion button .icon {
  display: inline-block;
  position: absolute;
  top: 18px;
  right: 0;
  width: 22px;
  height: 22px;
  border: 1px solid;
  border-radius: 22px;
}
.accordion button .icon::before {
  display: block;
  position: absolute;
  content: "";
  top: 9px;
  left: 5px;
  width: 10px;
  height: 2px;
  background: currentColor;
}
.accordion button .icon::after {
  display: block;
  position: absolute;
  content: "";
  top: 5px;
  left: 9px;
  width: 2px;
  height: 10px;
  background: currentColor;
}
.accordion button[aria-expanded=true] {
  color: #556ee6;
}
.accordion button[aria-expanded=true] .icon::after {
  width: 0;
}
.accordion button[aria-expanded=true] + .accordion-content {
  opacity: 1;
  max-height: inherit;
  transition: all 200ms linear;
  will-change: opacity, max-height;
}
.accordion .accordion-content {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 200ms linear, max-height 200ms linear;
  will-change: opacity, max-height;
}
.accordion .accordion-content p {
  font-size: 1rem;
  font-weight: 300;
  margin: 2em 0;
}
</style>